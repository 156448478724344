
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Image from "next/image";
import * as styles from "./Home.module.scss";
import * as stylesHero from "@/common/components/Hero/Hero.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StoryblokComponent, getStoryblokApi, storyblokEditable, useStoryblokState, } from "@storyblok/react";
import Head from "next/head";
import dynamic from "next/dynamic";
import Layout from "@/common/components/Layout";
import Hero from "@/common/components/Hero";
import { useSessionStorage } from "@/hooks/useSessionStorage";
import getConfig from "next/config";
import { analytics, gtmEvent } from "@/utils/gtm";
import InfoBar from "@/common/components/Story/InfoBar";
import useTranslation from "next-translate/useTranslation";
import { DEFAULT_LOCALE } from "@/utils/global";
const DynamicFollowBlock = dynamic(() => import("@/common/components/FollowBlock"), {
    loading: () => <p>Loading...</p>
});
export default function Home({ story, settings, locales, locale, defaultLocale, nestedData, weatherNow, }) {
    const { getSessionStorage, setSessionStorage } = useSessionStorage();
    const [isPresent, setIsPresent] = useState(false);
    story = useStoryblokState(story);
    const onChangeIsPresent = (e) => {
        setIsPresent(e.target.checked);
        //console.log("set session storage");
        setSessionStorage("isPresent", e.target.checked);
    };
    const getIsPresent = useCallback(() => {
        return getSessionStorage("isPresent");
    }, [getSessionStorage]);
    useEffect(() => {
        const isPresentSessionStorage = getIsPresent();
        if (isPresentSessionStorage !== null &&
            isPresentSessionStorage !== undefined &&
            isPresentSessionStorage !== "false" &&
            isPresentSessionStorage !== isPresent) {
            setIsPresent(isPresentSessionStorage);
        }
    }, [getIsPresent, isPresent]);
    const faq = story?.content?.faq && story.content.faq[0];
    const contentToUse = isPresent
        ? story?.content?.var_content
        : story?.content?.content;
    const { publicRuntimeConfig } = getConfig();
    const translatedSlugs = useMemo(() => {
        if (story?.translated_slugs?.length > 0) {
            return story.translated_slugs;
        }
    }, [story]);
    const { t } = useTranslation("common");
    let title = story ? story.name + ` - ${t("courchevel")}` : t("courchevel");
    let description = story
        ? story.name + ` - ${t("courchevel")}`
        : t("courchevel");
    let robots = "";
    if (story?.content?.meta_title) {
        title = story.content.meta_title;
    }
    if (story?.content?.meta_description) {
        description = story.content.meta_description;
    }
    if (story?.content?.hide_from_search) {
        robots = story.content.hide_from_search
            ? "noindex, nofollow"
            : "index, follow";
    }
    console.log("translatedSlug.lang", translatedSlugs);
    return (<div className={`${styles.homePage} js-wrapper`}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description}/>
        {robots && <meta name="robots" content={robots}/>}
        <link rel="x-default" href={`${publicRuntimeConfig.SITE_URL}/${DEFAULT_LOCALE}`}/>
        <link rel="alternate" hrefLang={DEFAULT_LOCALE} href={`${publicRuntimeConfig.SITE_URL}/${DEFAULT_LOCALE}`}/>
        {translatedSlugs?.map((translatedSlug, translatedSlugIndex) => {
            return (<link key={`${translatedSlug.lang}-${translatedSlugIndex}`} rel="alternate" hrefLang={translatedSlug.lang} href={`${publicRuntimeConfig.SITE_URL}/${`${translatedSlug.lang}/`}${translatedSlug.path === "home" ? "" : translatedSlug.path}`}/>);
        })}
        <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1"/>
      </Head>
      <Layout story={{
            id: story?.id,
            uuid: story?.uuid,
            name: story?.name,
            slug: story?.slug,
            full_slug: story?.full_slug,
            translated_slugs: story?.translated_slugs,
            path: story?.path,
            lang: story?.lang
        }} settings={settings} locales={locales} locale={locale} defaultLocale={defaultLocale}>
        <Hero content={story?.content?.hero} alternateContent={story?.content?.var_hero} isPresent={isPresent} onChangeIsPresent={onChangeIsPresent} weatherNow={weatherNow} settings={settings} {...storyblokEditable(story?.content)}/>
        <div className={stylesHero.heroPlaceholder}></div>
        {settings?.content?.infobar && settings?.content?.infobar[0] && (<InfoBar blok={settings?.content?.infobar[0]}/>)}
        <div className="content-blocks relative bg-white z-10" {...storyblokEditable(story.content)}>
          {contentToUse.map((nestedBlok) => {
            if (nestedBlok?.component) {
                return (<StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} nestedData={nestedData} settings={settings}/>);
            }
        })}
        </div>
        {/* <DynamicFollowBlock /> */}
        {faq && <StoryblokComponent blok={faq}/>}
      </Layout>
    </div>);
}
async function getServerSideProps(context) {
    // get the query object
    let isProduction = process.env.SITE_ENV === "production";
    // home is the default slug for the homepage in Storyblok
    let slug = "home";
    const nestedRelations = [];
    // load the draft version
    let sbParams = {
        version: isProduction ? "published" : "draft",
        resolve_links: "url",
        // resolve_relations:
        //   "internal_activities.items,internal_listing.item,livecams_block.list",
        resolve_relations: "internal_listing.item,livecams_block.list",
        language: context.locale
    };
    const storyblokApi = getStoryblokApi();
    let { data } = await storyblokApi.get(`cdn/stories/${slug}`, sbParams);
    let { data: settings } = await storyblokApi.get("cdn/stories/settings", sbParams);
    // let drawersData = null;
    // if (settings?.story?.content?.header) {
    //   const header = settings?.story?.content.header[0];
    //   console.log("HEADER", header);
    //   if (header) {
    //     const webcams = header?.webcam_link;
    //     const weather = header?.weather_link;
    //     const trails = header?.trail_link;
    //     const ids = [webcams, weather, trails].map((item) => item[0]?.link?.id);
    //     console.log("WEBCAMS", webcams, ids);
    //     const lumiplanDrawersParams = {
    //       version: isProduction ? "published" : "draft",
    //       by_uuids: ids.join(","),
    //       // excluding_fields: excluding_fields,
    //       // resolve_relations: "activitypage",
    //       language: context.locale,
    //     };
    //     if (ids.length > 0) {
    //       let lumiplanDrawers = await storyblokApi.get(
    //         `cdn/stories`,
    //         lumiplanDrawersParams
    //       );
    //       if (lumiplanDrawers?.data?.stories) {
    //         drawersData = lumiplanDrawers.data?.stories;
    //       }
    //     }
    //   }
    // }
    // Write a function to recursively get nested relations for objects with the key/value combination equals to the arguments
    const getNestedRelations = (obj, componentName, componentUuidList) => {
        if (obj.item && obj.item.content && obj.item.content.component) {
            if (obj.item.content.component === componentName) {
                const uuids = obj.item.content[componentUuidList];
                // Check if the strings contained in uuid are not already in the nestedRelations array
                // If not, push them to the array
                for (let i = 0; i < uuids.length; i++) {
                    if (!nestedRelations.includes(uuids[i])) {
                        nestedRelations.push(uuids[i]);
                    }
                }
            }
        }
    };
    if (data && data.story && data.story.content && data.story.content.content) {
        for (let i = 0; i < data.story.content.content.length; i++) {
            const storyBlok = data.story.content.content[i];
            if (storyBlok && storyBlok.source && storyBlok.source[0]) {
                if (storyBlok.source[0].component === "internal_activities") {
                    if (storyBlok.source[0].items && storyBlok.source[0].items.length) {
                        for (let j = 0; j < storyBlok.source[0].items.length; j++) {
                            const item = storyBlok.source[0].items[j];
                            if (!nestedRelations.includes(item)) {
                                nestedRelations.push(item);
                            }
                        }
                    }
                }
                getNestedRelations(storyBlok.source[0], "activities_list", "list");
            }
        }
    }
    if (data &&
        data.story &&
        data.story.content &&
        data.story.content.var_content) {
        for (let i = 0; i < data.story.content.var_content.length; i++) {
            const storyBlok = data.story.content.var_content[i];
            if (storyBlok && storyBlok.source && storyBlok.source[0]) {
                if (storyBlok.source[0].component === "internal_activities" ||
                    storyBlok.source[0].component === "internal_blog_articles") {
                    if (storyBlok.source[0].items && storyBlok.source[0].items.length) {
                        for (let j = 0; j < storyBlok.source[0].items.length; j++) {
                            const item = storyBlok.source[0].items[j];
                            if (!nestedRelations.includes(item)) {
                                nestedRelations.push(item);
                            }
                        }
                    }
                }
                getNestedRelations(storyBlok.source[0], "activities_list", "list");
            }
        }
    }
    const uniqueNestedRelations = [...new Set(nestedRelations)];
    const nestedStoriesParams = {
        version: isProduction ? "published" : "draft",
        by_uuids: uniqueNestedRelations.join(","),
        excluding_fields: "created_at,updated_at,sort_by_date,release_id,cross_content,apidae,faq,second_cross_content,content",
        language: context.locale
    };
    let { data: nestedData } = await storyblokApi.get(`cdn/stories`, nestedStoriesParams);
    // const nestedData = false;
    // Query the Storyblok Content Delivery API. Get the /blog/posts where the field categories contains the category uid
    let categoryParams = {
        version: isProduction ? "published" : "draft",
        resolve_links: "url",
        language: context.locale
    };
    let agendaPosts = null;
    // if (data && data.story && data.story.content) {
    //   // Find the block with the component property set to "agenda_block"
    //   let agendaBlock = data.story.content.content.find(
    //     (block) => block.component === "agenda_block"
    //   );
    //   // Get the category filter from the agenda_block
    //   let categoryFilter = agendaBlock.category_filter;
    //   let { data: posts } = await storyblokApi.get(`cdn/stories/`, {
    //     ...categoryParams,
    //     sort_by: "content.date:desc",
    //     per_page: 6,
    //     filter_query: {
    //       categories: {
    //         any_in_array: categoryFilter,
    //       },
    //     },
    //   });
    //   agendaPosts = posts;
    // }
    let weatherNowData = null;
    let weatherNow = null;
    try {
        const responseWeather = await fetch(`${process.env.SITE_URL}/api/lumiplan/get-weather-forecast`);
        if (!responseWeather.ok || responseWeather.status !== 200) {
            console.error(`Error fetchWeatherForecast(response NOK) - Status code: ${responseWeather.status}`);
        }
        else {
            const weatherData = await responseWeather.json();
            if (weatherData?.content?.resorts?.length > 0) {
                const resortData = weatherData?.content?.resorts[0];
                let weatherZonesStation = resortData?.weatherZones.find((weatherZone) => weatherZone.name?.match(/COURCHEVEL/i));
                // Build a dynamic list of dates from weatherZonesMap first item
                let weatherTodaysData = weatherZonesStation?.weatherInfos[0];
                const periodFromCurrentTime = new Date().getHours() < 12 ? "am" : "pm";
                weatherNow =
                    weatherTodaysData && periodFromCurrentTime
                        ? weatherTodaysData[periodFromCurrentTime]
                        : null;
            }
        }
    }
    catch (e) {
        console.log("Catch error fetchWeatherForecast", e);
    }
    // If the locale is "default" or "fr" and the context.url doesn't contain a locale, redirect to the url with the locale prefix
    // if (
    //   (context.locale === "default" || context.locale === "fr") &&
    //   !context.resolvedUrl.includes(`/${context.locale}`)
    // ) {
    //   console.log(
    //     'Redirect to "/fr"',
    //     `/${context.locale}${context.resolvedUrl}`
    //   );
    //   return {
    //     redirect: {
    //       destination: `/${context.locale}${context.resolvedUrl}`,
    //       permanent: true,
    //     },
    //   };
    // }
    return {
        props: {
            locales: context.locales,
            locale: context.locale,
            defaultLocale: context.defaultLocale,
            story: data ? data.story : false,
            nestedData: nestedData && nestedData.stories ? nestedData.stories : [],
            key: data ? data.story.id : false,
            settings: settings ? settings.story : false,
            agendaPosts: agendaPosts ? agendaPosts.stories : false,
            weatherNow,
            referer: context?.req?.headers?.referer || false
        }
    };
}

    async function __Next_Translate__getServerSideProps__1938ba26eab__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1938ba26eab__ as getServerSideProps }
  